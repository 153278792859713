<template>
  <div>
    <AdminTemplate class="admin-register-list disabled-print">
      <div class="admin-header">
        <h1>สร้างใบเสร็จ</h1>
      </div>
      <el-card class="admin-search-box">
        <el-form ref="form" :model="detail" label-position="top">
          <el-row :gutter="10">
            <el-col :sm="24" :md="12">
              <el-form-item label="เลขที่">
                <el-input v-model="detail.number"></el-input> </el-form-item
            ></el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="นาม">
                <el-input v-model="detail.name"></el-input> </el-form-item
            ></el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="Address / ที่อยู่">
                <el-input
                  v-model="detail.address"
                  placeholder="Address / ที่อยู่"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="District / ตำบล">
                <el-autocomplete
                  class="width-100"
                  popper-class="popper-auto-address"
                  v-model="detail.district"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      querySearch(queryString, cb, 'district')
                  "
                  placeholder="District / ตำบล"
                  @select="handleSelectAddress"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="Amphoe / อำเภอ">
                <el-autocomplete
                  class="width-100"
                  popper-class="popper-auto-address"
                  v-model="detail.amphoe"
                  :fetch-suggestions="
                    (queryString, cb) => querySearch(queryString, cb, 'amphoe')
                  "
                  placeholder="Amphoe / อำเภอ"
                  @select="handleSelectAddress"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="City / จังหวัด">
                <el-autocomplete
                  class="width-100"
                  popper-class="popper-auto-address"
                  v-model="detail.city"
                  :fetch-suggestions="
                    (queryString, cb) => querySearch(queryString, cb, 'city')
                  "
                  placeholder="City / จังหวัด"
                  @select="handleSelectAddress"
                ></el-autocomplete>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12">
              <el-form-item label="Zip / รหัสไปรษณี">
                <el-autocomplete
                  class="width-100"
                  popper-class="popper-auto-address"
                  v-model="detail.zipcode"
                  :fetch-suggestions="
                    (queryString, cb) => querySearch(queryString, cb, 'zipcode')
                  "
                  placeholder="Zip / รหัสไปรษณี"
                  @select="handleSelectAddress"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :sm="24">
              <h2>รายการ</h2>
              <el-row
                :gutter="10"
                v-for="(list, index) in detail.lists"
                :key="index"
                class="mt-10-px"
              >
                <el-col :span="4">
                  <el-input-number
                    v-model="list.number"
                    :min="1"
                    class="width-100"
                  ></el-input-number
                ></el-col>
                <el-col :span="14"
                  ><el-input v-model="list.name"></el-input
                ></el-col>
                <el-col :span="detail.lists.length > 1 ? 5 : 6"
                  ><el-input-number
                    v-model="list.price"
                    class="width-100"
                  ></el-input-number
                ></el-col>
                <el-col v-if="detail.lists.length > 1" :span="1"
                  ><div class="delete-box">
                    <i @click="delList(index)" class="el-icon-delete"></i></div
                ></el-col>
              </el-row>
              <p @click="addList" class="add-list">+ เพิ่มรายการ</p>
            </el-col>
          </el-row>
        </el-form>
      </el-card>

      <div class="mt-20-px">
        <PreviewReceipt :preview="true" :detail="detail" />
      </div>
    </AdminTemplate>
    <PreviewReceipt :preview="false" :detail="detail" />
  </div>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import PreviewReceipt from "@/components/preview/PreviewReceipt";
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from "thai-address-database";
// import { status } from "@/maxins/status";
// import { HTTP } from "@/plugins/axios.js";
// import { auth } from "@/maxins/auth";

export default {
  name: "AdminPrintReceipt",
  components: {
    AdminTemplate,
    PreviewReceipt,
  },
  data() {
    return {
      detail: {
        number: "",
        name: "",
        address: "",
        district: "",
        amphoe: "",
        city: "",
        zipcode: "",
        lists: [
          {
            number: 1,
            name: "",
            price: "",
          },
        ],
      },
    };
  },
  methods: {
    querySearch(queryString, cb, type) {
      var results = [];

      switch (type) {
        case "district":
          results = searchAddressByDistrict(queryString);
          console.log("searchAddressByDistrict", queryString, results);
          break;
        case "amphoe":
          results = searchAddressByAmphoe(queryString);
          break;
        case "city":
          results = searchAddressByProvince(queryString);
          break;
        case "zipcode":
          results = searchAddressByZipcode(queryString);
          break;
        default:
          break;
      }
      console.log("querySearch", results);
      var resultsConvert = results.map((item) => {
        let value = `${item.district} » ${item.amphoe} » ${item.province} » ${item.zipcode}`;
        return { value: value, data: item };
      });

      cb(resultsConvert);
    },
    handleSelectAddress(value) {
      this.detail.district = value.data.district;
      this.detail.amphoe = value.data.amphoe;
      this.detail.city = value.data.province;
      this.detail.zipcode = value.data.zipcode;
    },
    addList() {
      let obj = {
        number: 1,
        name: "",
        price: "",
      };

      this.detail.lists.push(obj);
    },
    delList(index) {
      this.detail.lists.splice(index, 1);
    },
  },
};
</script>
