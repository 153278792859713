<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div>
      <div class="form-company">
        <div class="header-form-company">
          <h1>Add user</h1>
          <p>เพิ่มผู้ใช้</p>
        </div>
      </div>

      <el-form
        ref="formInfo"
        :model="formInfo"
        label-position="top"
        class="register-form"
      >
        <InfoShortForm
          :members="formInfo.members"
          @updateFormInfo="updateFormInfo"
          @removeMember="removeMember"
        />
      </el-form>

      <div class="form-payment">
        <el-form
          ref="formPayment"
          :model="formPayment"
          label-position="top"
          :rules="rulesPayment"
          class="register-form"
        >
          <PaymentForm @updateFormPayment="updateFormPayment" />
        </el-form>
      </div>

      <div class="action">
        <el-button @click="$router.push('/admin-registered')">ยกเลิก</el-button>
        <el-button @click="registerValidate" type="primary">เพิ่ม</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoShortForm from "@/components/register/InfoShortForm";
import PaymentForm from "@/components/register/PaymentForm";

import { HTTP } from "@/plugins/axios.js";

export default {
  name: "RegisterPage",
  components: {
    InfoShortForm,
    PaymentForm,
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please input email"));
      } else {
        let regx = /^[\wก-ฮ-.]+@([\wก-ฮ-]+.)+[\wก-ฮ-]{2,4}$/g;
        let match = regx.test(value);
        if (match) {
          callback();
        } else {
          callback(new Error("Please input correct email"));
        }
      }
    };
    return {
      closeRegister: true,
      formCompany: {
        companyName: "",
        address: "",
        district: "",
        amphoe: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        tel: "",
        fax: "",
        email: "",
      },
      rulesCompany: {
        companyName: [
          {
            required: true,
            message: "Please input Organization,Company",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Please input Address",
            trigger: "blur",
          },
        ],
        district: [
          {
            required: true,
            message: "Please input District",
            trigger: "blur",
          },
        ],
        amphoe: [
          {
            required: true,
            message: "Please input Amphoe",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "Please input City",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Please input Country",
            trigger: "blur",
          },
        ],
        zipcode: [
          {
            required: true,
            message: "Please input Zip code",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "Please input Tel",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input Email",
            trigger: "blur",
          },
          { validator: checkEmail, trigger: "blur" },
        ],
      },

      formInfo: {
        members: [
          {
            prefixTH: "",
            nameTH: "",
            lastNameTH: "",
            prefixEN: "",
            nameEN: "",
            lastNameEN: "",
            companyName: "",
            addressType: "home",
            address: "",
            district: "",
            amphoe: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            tel: "",
            fax: "",
            email: "",
            registrationFees: "",
            professionalNo: "",
            idCard: "",
            supporter: "",
          },
        ],
      },

      formPayment: {
        paymentType: "",
        bankName: "",
        branch: "",
        accountNumber: "",
        date: "",
      },
      rulesPayment: {
        paymentType: [
          {
            required: true,
            message: "Please select Method payment",
            trigger: "change",
          },
        ],
      },
      fullscreenLoading: false,
    };
  },

  methods: {
    updateFormCompany(key, value) {
      this.formCompany[key] = value;
      if (
        key === "district" ||
        key === "amphoe" ||
        key === "city" ||
        key === "zipcode"
      ) {
        this.$refs["formCompany"].validateField("district");
        this.$refs["formCompany"].validateField("amphoe");
        this.$refs["formCompany"].validateField("city");
        this.$refs["formCompany"].validateField("zipcode");
      }
    },
    updateFormInfo(key, value, index) {
      this.formInfo.members[index][key] = value;
      if (
        key === "district" ||
        key === "amphoe" ||
        key === "city" ||
        key === "zipcode"
      ) {
        this.$refs["formInfo"].validateField(`members.${index}.district`);
        this.$refs["formInfo"].validateField(`members.${index}.amphoe`);
        this.$refs["formInfo"].validateField(`members.${index}.city`);
        this.$refs["formInfo"].validateField(`members.${index}.zipcode`);
      }
    },
    updateFormPayment(key, value) {
      this.formPayment[key] = value;
    },
    registerValidate() {
      if (this.$route.params.type === "group") {
        this.$refs["formCompany"].validate((valid) => {
          if (valid) {
            this.$refs["formInfo"].validate((valid) => {
              if (valid) {
                this.$refs["formPayment"].validate((valid) => {
                  if (valid) {
                    this.register();
                  }
                });
              }
            });
          }
        });
      } else {
        this.$refs["formInfo"].validate((valid) => {
          if (valid) {
            this.$refs["formPayment"].validate((valid) => {
              if (valid) {
                this.register();
              }
            });
          }
        });
      }
    },
    async register() {
      this.fullscreenLoading = true;
      let memberEmail = [];
      let members = this.formInfo.members.map((item) => {
        memberEmail.push(item.email);
        return {
          id_card: item.idCard,
          supporter: item.supporter,
          registration_fees: item.registrationFees,
          no_professional: item.professionalNo,
          institution: item.companyName,
          address: item.address,
          address_type: item.addressType,
          district: item.district,
          amphoe: item.amphoe,
          city: item.city,
          country: item.country,
          email: item.email,
          fax: item.fax,
          first_name: item.nameTH,
          last_name: item.lastNameTH,
          first_name_en: item.nameEN,
          last_name_en: item.lastNameEN,
          prefix: item.prefixTH,
          prefix_en: item.prefixEN,
          state: item.state,
          telephone: item.tel,
          zipcode: item.zipcode,
        };
      });
      let obj = {
        register_type: this.$route.params.type,
        institution: this.formCompany.companyName,
        company_address_type: "office",
        company_address: this.formCompany.address,
        company_city: this.formCompany.city,
        company_district: this.formCompany.district,
        company_amphoe: this.formCompany.amphoe,
        company_state: this.formCompany.state,
        company_country: this.formCompany.country,
        company_zipcode: this.formCompany.zipcode,
        company_telephone: this.formCompany.tel,
        company_fax: this.formCompany.fax,
        company_email:
          this.$route.params.type === "group"
            ? this.formCompany.email
            : members[0].email,
        members: members,
        payment_type: this.formPayment.paymentType,
        bank: this.formPayment.bankName,
        branch: this.formPayment.branch,
        bank_no: this.formPayment.accountNumber,
        transfer_date: this.formPayment.date
          ? new Date(this.formPayment.date).getTime()
          : Date.now(),
        memberEmail: memberEmail,
      };

      let res = await HTTP.post(`/register/create`, obj);

      if (res.data.success) {
        this.$router.push(`/admin-registered`);
      } else {
        if (res.data.message === "Email duplicate") {
          this.$message({
            message: `อีเมลถูกใช้งานแล้ว ${res.data.data.toString()}`,
            type: "warning",
          });
        }
      }

      this.fullscreenLoading = false;
    },
  },
};
</script>
