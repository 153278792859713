<template>
  <div class="page-receipt" :class="{ preview: preview }">
    <div class="icon-print">
      <i @click="print" class="el-icon-printer disabled-print"></i>
    </div>
    <div class="receipt-header">
      <div>
        <el-image
          :src="require('@/assets/images/logo-renal.png')"
          class="logo"
        ></el-image>
      </div>

      <div>
        <p>
          <b
            >หน่วยวิชาโรคไต ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์
            มหาวิทยาลัยเชียงใหม่</b
          ><br />Renal Division, Department of Medicine, Faculty of Medicine,
          <br />Chiang Mai University<br /><b
            >อ.เมือง จ.เชียงใหม่ 50200 โทร/แฟกซ์ (053) 895235</b
          ><br />Tel & Fax: (053895235)
        </p>
      </div>
    </div>

    <div class="receipt-no">
      <span><b>เล่มที่:</b> 1<br /></span>
      <h1>ใบเสร็จรับเงิน</h1>
      <span><b>เลขที่:</b> {{ detail.number }}</span>
    </div>

    <div class="receipt-address">
      <span>
        <b>นาม:</b> {{ detail.name }}<br />

        <b>ที่อยู่:</b>
        {{ detail.address }}
        {{ `${detail.district ? "ต." : ""}${detail.district}` }}
        {{ `${detail.district ? "อ." : ""}${detail.amphoe}` }}
        {{ `${detail.district ? "จ." : ""}${detail.amphoe}` }}
        {{ detail.zipcode }}
      </span>
    </div>
    <div class="receipt-table-list">
      <div class="table-header">
        <el-row :gutter="20">
          <el-col :span="4">
            <h3>จำนวน</h3>
          </el-col>
          <el-col :span="10">
            <h3>รายการ</h3>
          </el-col>
          <el-col :span="4">
            <h3>ราคาหน่วยละ</h3>
          </el-col>
          <el-col :span="4">
            <h3>จำนวนเงิน</h3>
          </el-col>
        </el-row>
      </div>

      <div class="table-body">
        <el-row
          v-for="(item, index) in detail.lists"
          :key="item.id"
          :gutter="20"
          class="table-item full-a4"
        >
          <el-col :span="4" class="center" :class="`list-item-${index}`">
            <p>{{ item.number }}</p>
          </el-col>
          <el-col :span="10" :class="`list-item-${index}`">
            <p>{{ item.name }}</p>
          </el-col>
          <el-col :span="4" class="center" :class="`list-item-${index}`">
            <p>{{ item.price | commaDecimal }}</p>
          </el-col>
          <el-col :span="4" class="right" :class="`list-item-${index}`">
            <p>{{ (item.price * item.number) | commaDecimal }}</p>
          </el-col>
        </el-row>
      </div>

      <div class="table-footer">
        <el-row :gutter="20">
          <el-col :span="14">
            <div class="bg-white">
              <h3>{{ total | thaiBaht }}</h3>
            </div>
          </el-col>

          <el-col :span="4" class="center">
            <h3>รวมเงิน</h3>
          </el-col>

          <el-col :span="6" class="right">
            <div class="bg-white">
              <h3>{{ total | commaDecimal }}</h3>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="receipt-signature">
      <p>
        ผู้รับเงิน..................................................................................
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewReceipt",

  props: ["preview", "detail"],
  computed: {
    total() {
      let total = 0;
      this.detail.lists.map((item) => {
        total += item.price * item.number;
      });

      return total;
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
