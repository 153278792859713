<template>
  <div>
    <PreviewCertificateTH name="พว.พัฐฐิฎาภรณ์ ขำเปรื่องเดช" />
    <div class="pagebreak">
      <PreviewCertificate name="Phattitapon Kampreuangdech" />
    </div>

    <AdminTemplate class="admin-register-list disabled-print"> </AdminTemplate>
  </div>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import PreviewCertificate from "@/components/preview/PreviewCertificate";
import PreviewCertificateTH from "@/components/preview/PreviewCertificateTH";
import { status } from "@/maxins/status";
import { HTTP } from "@/plugins/axios.js";
import { auth } from "@/maxins/auth";

export default {
  name: "AdminPrintCertificate",
  components: {
    AdminTemplate,
    PreviewCertificate,
    PreviewCertificateTH,
  },
  props: {
    msg: String,
  },
  computed: {},
  mixins: [status, auth],
  data() {
    return {
      form: {
        search: "",
        status: "ทั้งหมด",
      },

      payments: [],
      total: 0,
      limit: 10,
      page: 1,
      loadingList: true,
      loadingDetail: true,
      detail: null,
      fileUploadURL: process.env.VUE_APP_BASE_UPLOAD_FILE,
    };
  },
  mounted() {
    this.fetchPayment();
  },
  methods: {
    search() {
      this.loadingList = true;
      this.page = 1;
      this.fetchPayment();
    },
    handleCurrentChange(val) {
      this.loadingList = true;
      this.page = val;
      this.fetchPayment();
    },
    async fetchPayment() {
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status === "ทั้งหมด" ? "" : this.form.status,
          page: this.page,
          limit: this.limit,
        };
        let res = await HTTP.post(`/payment/get`, obj);

        if (res.data.success) {
          this.total = res.data.data.total;
          this.payments = res.data.data.result;
        }

        this.loadingList = false;
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loadingList = false;
      }
    },
    async fetchPaymentDetail(id) {
      this.loadingDetail = true;
      let res = await HTTP.get(`/payment/by/${id}`);

      if (res.data.success) {
        this.detail = res.data.data;
      }

      this.loadingDetail = false;
    },
    async deleteRegister(id) {
      this.loadingList = true;
      let res = await HTTP.delete(`/register/by/${id}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPayment();
      }

      this.loadingList = false;
    },
    async updatepaymentStatus(id, paymentCode, registerTypeId, status) {
      this.loadingList = true;
      this.loadingDetail = true;

      let obj = {
        payment_code: paymentCode,
        register_type_id: registerTypeId,
        status: status,
      };

      let res = await HTTP.put(`/payment-confirm/status`, obj);

      if (res.data.success) {
        this.$message({
          message: "อัปเดตสถานะสำเร็จ",
          type: "success",
        });
        this.detail = null;
        this.page = 1;
        this.fetchPayment();
        this.fetchPaymentDetail(id);
      }

      this.loadingDetail = false;
      this.loadingList = false;
    },
    async deletePayment(code) {
      this.loadingList = true;
      let res = await HTTP.delete(`/payment/by/${code}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPayment();
      }

      this.loadingList = false;
    },
  },
};
</script>
