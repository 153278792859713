<template>
  <div class="page-certificate th">
    <div class="certificate-content">
      <h1 class="certificate-institution">
        สถาบันหลัก ฝ่ายการพยาบาล โรงพยาบาลมหาราชนครเชียงใหม่
      </h1>
      <p class="certificate-pre">ให้ไว้เพื่อรับรองว่า</p>
      <h1 class="certificate-name">{{ name }}</h1>
      <p class="certificate-pre">เข้าร่วมประชุมวิชาการ</p>
      <p class="certificate-title">
        เรื่อง
        <b
          >"Renal Diseases and Biotechnology for Blood Purification (RB
          2024)"</b
        >
      </p>
      <p class="certificate-location">
        วันที่ 5-7 ธันวาคม 2567<br />
        ณ โรงแรมดิเอ็มเพรส แอนด์ คอนเวชั่น เซ็นเตอร์ จังหวัดเชียงใหม่
      </p>
      <p class="certificate-location">
        หน่วยคะแนนการศึกษาต่อเนื่องสาขาพยาบาลศาสตร์ 18 หน่วยคะแนน<br />รหัสหลักสูตร
        H003-L-67-C2-0015-1
      </p>
      <div class="signature-box">
        <div class="signature-center">
          <p>
            (นายวีรชาติ ชูฤทธิ์)<br />หัวหน้าฝ่ายการพยาบาล
            โรงพยาบาลมหาราชนครเชียงใหม่
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewCertificate",
  props: ["name"],
};
</script>
