<template>
  <div class="page-certificate">
    <div class="certificate-content">
      <h1 class="certificate-title">
        Internationnal Congress of<br />Renal Diseases & Biotechnology for Blood
        Purification
      </h1>
      <p class="certificate-pre">This is to certify that</p>
      <h1 class="certificate-name">{{ name }}</h1>
      <p class="certificate-location">
        Attended the Congress, Held in Chiang Main, Thailand<br />From December
        5-7, 2024
      </p>
      <div class="signature-box">
        <div class="signature-left">
          <p>
            Vuddhidej Ophascharoensuk, MD<br />Chief of Renal Devision,
            Department of Medicine
          </p>
        </div>
        <div class="signature-right">
          <p>Dusit Lumlertgul, MD<br />Chairman, Organizing committee</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewCertificate",
  props: ["name"],
};
</script>
