<template>
  <section id="home" class="page-box">
    <el-image :src="require('@/assets/images/slide.png')"></el-image>
    <div class="t-center">
      <el-button @click="openFile" type="success"
        >ดูรายชื่อผู้เข้าร่วม</el-button
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeComponent",
  props: {
    msg: String,
  },
  methods: {
    openFile() {
      window.open(
        "https://drive.google.com/file/d/1Y0RWSyPo5AOY43PJZGrfjyVCG_v6nOIf/view"
      );
    },
  },
};
</script>
