<template>
  <section id="schedule" class="page-box">
    <h1 class="title">{{ $t("menu.schedule") }}</h1>
    <el-row :gutter="20" class="schedule-list">
      <el-col :sm="24" :md="8">
        <a
          data-fancybox="schedule-1"
          :href="require('@/assets/images/schedule-1-1.png')"
          class="fancybox"
          ><el-card class="box-card" shadow="hover">
            <p><i class="el-icon-date"></i> December 5 , 2024</p>
          </el-card>
        </a>
        <a
          data-fancybox="schedule-1"
          :href="require('@/assets/images/schedule-1-2.png')"
          class="fancybox"
        >
        </a>
      </el-col>
      <el-col :sm="24" :md="8"
        ><a
          data-fancybox="schedule-2"
          :href="require('@/assets/images/schedule-2-1.png')"
          class="fancybox"
          ><el-card class="box-card" shadow="hover">
            <p><i class="el-icon-date"></i> December 6 , 2024</p>
          </el-card>
        </a>
        <a
          data-fancybox="schedule-2"
          :href="require('@/assets/images/schedule-2-2.png')"
          class="fancybox"
        >
        </a>
      </el-col>
      <el-col :sm="24" :md="8"
        ><a
          data-fancybox="schedule-3"
          :href="require('@/assets/images/schedule-3-1.png')"
          class="fancybox"
          ><el-card class="box-card" shadow="hover">
            <p><i class="el-icon-date"></i> December 7 , 2024</p>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  name: "ScheduleComponent",
  props: {
    msg: String,
  },
};
</script>
